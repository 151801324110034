import styled from 'styled-components';
import Img from 'gatsby-image';

import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import { MediumTextTransparent, HeaderMedium, BaseP } from 'components/typography';

export const GatsbyImage = styled(Img).attrs({ style: { position: 'inherit' } })`
  height: 100%;
  width: 100%;
`;

export const ImageBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  z-index: ${zIndex.body};

  ${GatsbyImage} {
    object-fit: cover;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    left: calc(50% - 48px);

    ${GatsbyImage} {
      position: relative;
    }
  }

  & > div {
    margin: auto 0;
  }
`;

export const PartnersWrapper = styled.div`
  margin-bottom: 64px;

  & > ${BaseP} {
    margin: 30px 0;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 135px;
  }
`;

export const WhiteContainer = styled.div`
  margin: 72px 0 64px;

  ${HeaderMedium} {
    margin: 16px 0 32px;
  }

  ${MediumTextTransparent} {
    margin-top: 16px;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    & > * {
      max-width: calc(50% - 160px);
    }

    ${MediumTextTransparent} {
      margin-bottom: 20px;
    }
  }
`;
