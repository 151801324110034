import React from 'react';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { color } from 'common/styledVars';
import { BaseP } from 'components/typography';

const Company = styled.div`
  min-height: 200px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  width: 100%;
  border-radius: 17px;
  background-color: #ffffff;
  box-shadow: 0 23px 30px 0 rgba(230, 237, 239, 0.6);
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .en .zh {
    display:none;
  }

  .en .kr {
    display:none;
  }

  .zh .en {
    display:none;
  }

  .zh .kr {
    display:none;
  }

  .kr .en {
    display:none;
  }

  .kr .zh {
    display:none;
  }
  
  &:hover {
    cursor:pointer;
    .tooltiptext {
      visibility: visible !important;
      -webkit-transition: all 0.3s ease-in;
      transition: all 0.3s ease-in;
      transform:scale(1.05);
    }
  }

  @media only screen and (max-width:981px) {
    max-width: 90%;
    margin-left:auto;
    margin-right:auto;
  }
  
  .tooltiptext {
    visibility: hidden;
    background-color: #fff;
    color: #fff;
    text-align: left;
    border-radius: 17px;
    padding: 25px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    top: 0;

    right: 0;
    line-height: 1.5;
    font-weight: 300;
    font-size:16px;
    color: #9db1ad;
    line-height: 25px;
    box-shadow: 0 23px 40px 0 rgba(82, 82, 82, 0.39);

  }

  ${BaseP} {
    margin: auto 0 0;
    color: ${color.black};
  }

`;

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(152px, 1fr));
  grid-gap: 20px;

  &:nth-of-type(1) {
    margin-bottom:20px !important;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }

  &.warehousing:nth-of-type(1) {
    margin-bottom:20px !important;
    grid-template-columns: repeat(auto-fill, minmax(152px, 1fr)) !important; 
  }

  @media only screen and (max-width:981px) {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
`;

const ImgBox = styled.div`
  margin: auto;
  position: absolute;
  top: 30px;
  left: 5px;
  bottom: 72px;
  right: 5px;
  display: flex;

  img {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
`;

export interface CarouselCompany {
  name: string;
  src: string;
  tooltip: string;
  class: string
}

interface OwnProps {
  items: CarouselCompany[];
  className?: string;
}

const Carousel = ({ items, className }: OwnProps) => {
  const intl = useIntl()
  return(
  <Container className={className}>
    {items.map((company, i) => (
      <Company key={i}>
        <ImgBox>
          <img src={company.src} alt={`${company.name} logo`} />
        </ImgBox>
        <BaseP>{company.name}</BaseP>
        <div className={intl.formatMessage({ id: "language" })}>
          <div className="en">
            <span className={company.class}>{company.tooltip}</span>
          </div>
          <div className="zh">
            <span className={company.class}>{company.tooltipZH}</span>
          </div>
          <div className="kr">
            <span className={company.class}>{company.tooltipKO}</span>
          </div>
        </div>
      </Company>
    ))}
  </Container>
);
};

export default styled(Carousel)``;
