import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { breakpoint } from 'common/styledVars';
import { GatsbyImage } from 'components/pages/Partners/styled';
import { BaseTransparentP, HeaderMedium, BaseP } from 'components/typography';
import Carousel from 'components/Carousel';
import { RelativeWrapper as RelativeWrapperBase, WidthWrapper } from 'components/wrappers';

export const MainSection = styled(WidthWrapper)`
  & > ${BaseP} {
    margin-bottom: 16px;
    &:last-of-type {
      margin-top: 64px;
    }
  }

  ${Carousel} {
    margin-bottom: 64px;

    img {
      transform: scale(0.7);
    }
  }

  ${BaseTransparentP} {
    max-width: 540px;
    margin-bottom: 32px;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    ${BaseP} {
      margin-bottom: 20px;
      &:last-of-type {
        margin-top: 120px;
      }
    }

    ${Carousel} {
      margin-bottom: 120px;
    }

    ${BaseTransparentP} {
      margin-bottom: 50px;
    }
  }
`;

export const RelativeWrapperWithImage = styled(RelativeWrapperBase)`
  margin-bottom: 72px;
  @media ${mediaMin(breakpoint.desktop)} {
    margin-bottom: 112px;

    ${GatsbyImage} {
      align-items: center;
      height: 80%;
      align-self: center;
    }
  }
`;

export const HeaderContainer = styled.div`
  margin: 72px 0 32px;

  ${BaseTransparentP} {
    margin-top: 16px;
  }

  ${HeaderMedium} {
    margin: 16px 0 32px;
  }

  @media ${mediaMin(breakpoint.desktop)} {
    max-width: calc(50% - 98px);
    ${BaseTransparentP} {
      margin-top: 80px;
    }

    ${HeaderMedium} {
      margin: 42px 0 80px;
    }
  }
`;
