import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet"
import Carousel from 'components/Carousel';
import Layout from 'components/Layout';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import { BaseTransparentP, HeaderMedium, BaseP } from 'components/typography';
import { ImageBox, GatsbyImage } from 'components/pages/Partners/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  HeaderContainer,
  RelativeWrapperWithImage,
  MainSection,
} from 'components/pages/Integrations/styled';
import { WidthWrapper } from 'components/wrappers';
import { platformCompanies, moreCompanies, mainCompanies} from 'components/pages/Integrations/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import Platform from 'images/platform2.jpg';

const ImagesContainer = styled.div`
  display: block;
    
`;
const MainTitle = styled.h1`
    font-size: 14px;
    font-weight: 300;
    color: #00AF85;
  
  @media ${mediaMin(breakpoint.desktop)} {
      font-size: 16px;
  }

`;
const Breadcrumbs = styled.p`
    padding: 25px 0px 25px 50px;
<<<<<<< HEAD
<<<<<<< HEAD
    width:100%;
    border-bottom: 1px solid #e3ebe9;
=======
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
    width:100%;
    border-bottom: 1px solid #e3ebe9;
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;

const OurStoryPage = () => {
  const intl = useIntl()
  const data = useStaticQuery(graphql`
    query imgBridgeQueryAndImgBridgeQuery  {
      file(relativePath: { eq: "platform2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Helmet>
        {/* <title>Integrate Your eCommerce Store | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "meta-title-ecommerce-store-integration" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "ecommerce-store-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "ecommerce-store-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "ecommerce-store-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "ecommerce-store-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="" />
        <meta property="og:title" content={intl.formatMessage({ id: "meta-title-ecommerce-store-integration" })} />
        <meta property="og:description" content="Ecommerce store integration is easy & simple with Shipkoo. Simply integrate your favorite eCommerce channels and marketplaces such as Amazon, Shopify, & eBay." />
        <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-ecommerce-store-integration" })} />
        <meta name="twitter:description" content="Ecommerce store integration is easy & simple with Shipkoo. Simply integrate your favorite eCommerce channels and marketplaces such as Amazon, Shopify, & eBay." />
      </Helmet>
      <Breadcrumbs><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-platform-integrations" })}</Breadcrumbs>
      <RelativeWrapperWithImage>
        <WidthWrapper whiteMobile>
          <HeaderContainer>
            <MainTitle>
              {intl.formatMessage({ id: "ecommerce-store-integration-subheader" })}
            </MainTitle>  
            <HeaderMedium>
              {intl.formatMessage({ id: "ecommerce-store-integration-header" })}
            </HeaderMedium>
            <BaseTransparentP>
              {intl.formatMessage({ id: "ecommerce-store-integration-description" })}
            </BaseTransparentP>
          </HeaderContainer>
        </WidthWrapper>
        <ImageBox>
          <GatsbyImage fluid={data.file.childImageSharp.fluid} alt="shipkoo ecommerce solutions shipping integration for popular eCommerce channels and marketplace" />
          <MobileFilter />
        </ImageBox>
      </RelativeWrapperWithImage>

      <MainSection>
        <BaseP>{intl.formatMessage({ id: "ecommerce-store-integration-platform-title" })}</BaseP>
        <BaseTransparentP>
          {intl.formatMessage({ id: "ecommerce-store-integration-platform-description" })}
        </BaseTransparentP>
        <Carousel items={mainCompanies} />
        <Carousel items={platformCompanies} />
        <BaseP>{intl.formatMessage({ id: "ecommerce-store-integration-covered-title" })}</BaseP>
        <BaseTransparentP>
          {intl.formatMessage({ id: "ecommerce-store-integration-covered-description" })}
        </BaseTransparentP>
        <Carousel items={moreCompanies} />
      </MainSection>
    </Layout>
  );
};

export default OurStoryPage;
