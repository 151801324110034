import { CarouselCompany } from 'components/Carousel';

import Ebay from 'images/companies/integration/ebay.png';
import Amazon from 'images/companies/integration/amazon.png';
import BigCommerce from 'images/companies/integration/big-commerce.png';
import Anatwine from 'images/companies/integration/anatwine.png';
import Etsy from 'images/companies/integration/etsy.png';
import Catch from 'images/companies/integration/catch.png';

import Brayola from 'images/companies/integration/brayola.png';
import DavidJones from 'images/companies/integration/david-jones.png';
import Joor from 'images/companies/integration/joor.png';
import Magento from 'images/companies/integration/magento.png';
import Myer from 'images/companies/integration/myer.png';
import Nordstrom from 'images/companies/integration/nordstrom.png';

import Lazada from 'images/companies/integration/lazada.png';
import Shopify from 'images/companies/integration/shopify.png';
import Walmart from 'images/companies/integration/walmart.png';
import Orocommerce from 'images/companies/integration/oro-commerce.png';
import Rakuten from 'images/companies/integration/rakuten.png';
import Zalora from 'images/companies/integration/zalora.png';

import WooCommerce from 'images/companies/integration/woo-commerce.png';
import TradeMe from 'images/companies/integration/trade-me.png';
import TheIconic from 'images/companies/integration/the-iconic.png';

import OracleBronto from 'images/companies/integration/oracle-bronto.png';
import Westfield from 'images/companies/integration/westfield.png';
import GoogleShopping from 'images/companies/integration/google-shopping.png';
import NetSuite from 'images/companies/integration/netsuite.png';
import Apparel21 from 'images/companies/integration/apparel.png';
import GetPice from 'images/companies/integration/getprice.png';

import AdRoll from 'images/companies/integration/adroll.png';
import CegidGroup from 'images/companies/integration/cegid.png';
import Cloudinary from 'images/companies/integration/cloudinary.png';
import Barilliance from 'images/companies/integration/barilliance.png';
import FluentRetail from 'images/companies/integration/fluentretail.png';
import Facebook from 'images/companies/integration/facebook.png';

import Emarsys from 'images/companies/integration/emarsys.png';
import ShopBot from 'images/companies/integration/shop-bot.png';
import RewardStyle from 'images/companies/integration/reward-style.png';
import Shippit from 'images/companies/integration/shippit.png';
import Purecomm from 'images/companies/integration/purecomm.png';
import PredictSpring from 'images/companies/integration/predict-spring.png';
import Coupang from 'images/companies/integration/coupang-logo.png';


export const mainCompanies: CarouselCompany[] = [
  {
    name: 'eBay',
    src: Ebay,
    tooltip: 'eBay sellers use Shipkoo to help grow their business through smarter shipping. Our eBay integration lets you enjoy the convenience of Shipkoo without any disruptions to the business flows you have built on the eBay platform.',
    tooltipZH: 'eBay卖家使用Shipkoo来帮助他们通过更智能的运输来增长业务。Shipkoo的系统让您享受更多的便利操作，并不会破坏您在eBay平台上构建的业务流程。',
    tooltipKO: '이베이 판매자들은 더 스마트한 배송을 통해 그들의 사업을 성장시키기 위해 Shipkoo를 사용한다. 우리의 eBay 통합은 당신이 이베이 플랫폼에 구축한 비즈니스 흐름에 지장을 주지 않고 Shipkoo의 편리함을 즐길 수 있게 해준다.',
    class:'tooltiptext',
  },
  {
    name: 'Amazon',
    src: Amazon,
    tooltip: 'We help many of our Amazon sellers to meet the complicated requirement by generating shipping labels and FBA fulfillment. Our integration will sync your orders in Amazon and everything needed to generate shipping labels seamlessly.',
    tooltipZH: '我们通过生成货运标签和FBA配送帮助许多亚马逊卖家满足复杂的要求，我们的系统对接将同步您在亚马逊系统中的订单以及无缝生成货运标签所需的一切。',
    tooltipKO: '우리는 선적 라벨과 FBA 이행품을 생성함으로써 많은 아마존 셀러들이 복잡한 요구 사항을 충족할 수 있도록 돕는다. 우리의 통합은 아마존에서의 당신의 주문과 배송 라벨을 완벽하게 만드는 데 필요한 모든 것을 동기화시킬 것이다.',
    class:'tooltiptext',
  },
  {
    name: 'Shopify',
    src: Shopify,
    tooltip: 'Shipkoo - Powering shipping for Shopify stores. The integration plugin is user-friendly and enables you to sync orders directly from Shopify and update your end customers with tracking.',
    tooltipZH: 'Shipkoo为Shopify商店提供航运服务并且对接插件，使您在Shopify的后台可以同步订单和终端客户对接配送情况。',
    tooltipKO: 'Shipkoo - Shopify 스토어에 대한 배송 전원 공급 통합 플러그인은 사용자에게 친숙하며 Shopify에서 직접 주문을 동기화하고 최종 고객을 추적하여 업데이트할 수 있다.',
    class:'tooltiptext',
  },
  {
    name: 'Magento',
    src: Magento,
    tooltip: 'Magento empowers thousands of retailers and brands with the best eCommerce platforms. Shipkoo integrate with your stores in Magento to help you to facilitate your eCommerce business to rapidly innovate and grow.',
    tooltipZH: 'Magento为成千上万的零售商和品牌商提供线上电子商务平台。Shipkoo通过对接您在Magento的商店，帮助您的电子商务业务快速创新和成长。',
    tooltipKO: 'Magento는 최고의 eCommerce 플랫폼으로 수천 개의 소매업체와 브랜드에 권한을 부여한다. Shipkoo는 Magento에 있는 상점과 통합되어 eCommerce 비즈니스가 빠르게 혁신하고 성장할 수 있도록 도와준다.',
    class:'tooltiptext',
  },
  {
    name: 'Big Commerce',
    src: BigCommerce,
    tooltip: 'BigCommerce is a technology company that provides a SaaS eCommerce platform for online sellers. Shipkoo syncs orders in BigCommerce to fuel your business with all the capabilities of enterprise eCommerce—without the cost or complexity.',
    tooltipZH: 'BigCommerce是一家为线上卖家提供SaaS电子商务平台的科技公司。无需成本也不具复杂性，Shipkoo在BigCommerce中同步您的订单，帮助拓展电子商务运营。',
    tooltipKO: '빅커머스(BigCommerce)는 온라인 판매자를 위한 SaaS eCommerce 플랫폼을 제공하는 기술 기업이다. Shipkoo는 비용이나 복잡성 없이 BigCommerce의 주문을 동기화하여 엔터프라이즈 eCommerce의 모든 기능을 통해 비즈니스에 활력을 불어넣는다.',
    class:'tooltiptext',
  },
  {
    name: 'Rakuten',
    src: Rakuten,
    tooltip: 'Rakuten is a Japanese electronic commerce and online retailing company based in Tokyo. Shipkoo integrates your eCommerce business hassle free in Rakuten, automating and tracking shipments all in our system.',
    tooltipZH: '乐天是一家总部位于东京的日本电子商务和在线零售公司。Shipkoo将您的电子商务业务整合到乐天，在我们的系统中自动化和跟踪发货。',
    tooltipKO: '라쿠텐은 도쿄에 본사를 둔 일본의 전자상거래 및 온라인 유통 기업이다. Shipkoo는 귀사의 eCommerce 비즈니스 번거로움을 Rakuten에 무료로 통합하여 당사 시스템의 모든 출하를 자동화 및 추적한다.',
    class:'tooltiptext',
  },
];

export const platformCompanies: CarouselCompany[] = [
   {
    name: 'Coupang',
    src: Coupang,
    altText: 'Coupang'
  },
  {
    name: 'Anatwine',
    src: Anatwine,
  },
  {
    name: 'Etsy',
    src: Etsy,
  },
  {
    name: 'Catch',
    src: Catch,
  },
  {
    name: 'Brayola',
    src: Brayola,
  },
  {
    name: 'David Jones',
    src: DavidJones,
  },
  {
    name: 'Joor',
    src: Joor,
  },
  {
    name: 'Myer',
    src: Myer,
  },
  {
    name: 'Nordstrom',
    src: Nordstrom,
  },
  {
    name: 'Lazada',
    src: Lazada,
  },
  {
    name: 'Walmart',
    src: Walmart,
  },
  {
    name: 'ORO Commerce',
    src: Orocommerce,
  },
  {
    name: 'Zalora',
    src: Zalora,
  },
  {
    name: 'WooCommerce',
    src: WooCommerce,
  },
  {
    name: 'TradeMe',
    src: TradeMe,
  },
  {
    name: 'The Iconic',
    src: TheIconic,
  },
];

export const moreCompanies: CarouselCompany[] = [
  {
    name: 'Oracle Bronto',
    src: OracleBronto,
  },
  {
    name: 'Westfield',
    src: Westfield,
  },
  {
    name: 'Google Shopping',
    src: GoogleShopping,
  },
  {
    name: 'NetSuite',
    src: NetSuite,
  },
  {
    name: 'Apparel 21',
    src: Apparel21,
  },
  {
    name: 'Get Price',
    src: GetPice,
  },
  {
    name: 'AdRoll',
    src: AdRoll,
  },
  {
    name: 'Cegid Group',
    src: CegidGroup,
  },
  {
    name: 'Cloudinary',
    src: Cloudinary,
  },
  {
    name: 'Barilliance',
    src: Barilliance,
  },
  {
    name: 'Fluent Retail',
    src: FluentRetail,
  },
  {
    name: 'Facebook',
    src: Facebook,
  },
  {
    name: 'emarsys',
    src: Emarsys,
  },
  {
    name: 'Shop Bot',
    src: ShopBot,
  },
  {
    name: 'reward Style',
    src: RewardStyle,
  },
  {
    name: 'Shippit',
    src: Shippit,
  },
  {
    name: 'Purecomm',
    src: Purecomm,
  },
  {
    name: 'predictspring',
    src: PredictSpring,
  },
];
