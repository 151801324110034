import styled from 'styled-components';

import { mediaMin } from 'common/styledMixins';
import { color, zIndex, breakpoint } from 'common/styledVars';

const BackgroundFilter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${zIndex.body + 1};
  background-color: ${color.black};
  opacity: 0.4;
`;

export const MobileFilter = styled(BackgroundFilter)`
  @media ${mediaMin(breakpoint.desktop)} {
    display: none;
  }
`;

export default BackgroundFilter;
